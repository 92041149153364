import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DataService } from '../data.service';
import { ConfigService } from '../config.service';
import { LocalizationService } from '../localization.service';
import { CommonService } from '../common.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {ProgressSpinnerMode} from '@angular/material/progress-spinner';
import {ThemePalette} from '@angular/material/core';
const DEFAULT_LOCALE = "en-US";

@Component({
    selector: 'app-mfa-form',
    templateUrl: './mfa.component.html',
    styleUrls: ['./mfa.component.scss']
})
export class MFAComponent implements OnInit {

    constructor(
        private router: Router,
        private data: DataService,
        private config: ConfigService,
        private route: ActivatedRoute,
        private localization: LocalizationService,
        public common: CommonService,
        private dialog: MatDialog
    ) {
        common.title = "";
        common.clearError();
        this.clearError();
    }

    entryFromLoginPage = false;
    newAccountOrResetAccount = false;
    phonenumber = "";
    securitycode = "";
    showSMSSetup = false;
    showTotpSetup = false;
    verificationCodeSent = false;
    qrCode = "";
    qrCodeString = "";
    challengeAnswer = "";
    prefferedMfaMethod = "";
    user = null;
    isSMSEnabled = false;
    isTotpEnabled = false;
    showTotpCodeForm = false;
    isAppInfoShown = false;
    invalidCode = false;
    isTotpClicked = false;
    isDisableSMSClicked = false;
    title = this.tr('access.mfa.title');
    error = "";
    numberOfenabledMethods = 0;
    redirect = false;
    disableContinue = false;
    phoneRequired = false;
    smsRequired = false;
    codeRequired = false;
    locale = DEFAULT_LOCALE;
    isHiddenSkipButton = false;
    mode: ProgressSpinnerMode = 'indeterminate';
    value = 32;
    color: ThemePalette = 'primary';

    async checkReAuthenticationSession() {
        if (this.newAccountOrResetAccount || this.entryFromLoginPage) {
            return true;
        } else {
            let reAuthenticated = await this.data.isReAuthSessionValid();
            if (reAuthenticated) {
                return true;
            }
            let url = '/reauthenticate';
            this.data.reAuthenticationAction = 'setupmfa';
            if(this.config.getParameterByName('origin')) {
                url += '?redirect=true&origin=' + encodeURIComponent(this.config.getParameterByName('origin'));
            }
            if(this.config.getParameterByName('target')) {
                url += '&target=' + encodeURIComponent(this.config.getParameterByName('target'));
            }
            if(this.config.getParameterByName('languagecode')) {
                url += '&languagecode=' + encodeURIComponent(this.config.getParameterByName('languagecode'));
            }
            await this.router.navigateByUrl(url);
        }
    }

    async ngOnInit() {
        
        try {
            this.common.beginPageLoading();
            let locale = this.config.getParameterByName('languagecode') ? this.config.getParameterByName('languagecode'): navigator.language || DEFAULT_LOCALE;
            this.locale = locale;
            let loggedIn = await this.data.isLoggedIn(
                this.config.getParameterByName('languagecode') ? this.config.getParameterByName('languagecode') : 'en-US',
                this.config.getParameterByName('origin') ? this.config.getParameterByName('origin') : 'NA',
                this.config.getParameterByName('target') ? this.config.getParameterByName('target') : 'NA',
                this.config.getParameterByName('loginType') ? this.config.getParameterByName('loginType') : 'NA',
                this.config.getParameterByName('ops') ? this.config.getParameterByName('ops') : 'NA'
            );
            if(!loggedIn){
                throw new Error("User not authenticated");
            }
            this.subscribeEntryPointSource();
            //take to re-auth if not (change password flow or fresh login)
            if (!(this.newAccountOrResetAccount || this.entryFromLoginPage)) {
                let reAuthenticated = await this.data.isReAuthSessionValid();
                if (!reAuthenticated) {
                    let url = '/reauthenticate';
                    this.data.reAuthenticationAction = 'setupmfa';
                    if(this.config.getParameterByName('origin')) {
                        url += '?redirect=true&origin=' + encodeURIComponent(this.config.getParameterByName('origin'));
                    }
                    if(this.config.getParameterByName('target')) {
                        url += '&target=' + encodeURIComponent(this.config.getParameterByName('target'));
                    }
                    if(this.config.getParameterByName('languagecode')) {
                        url += '&languagecode=' + encodeURIComponent(this.config.getParameterByName('languagecode'));
                    }
                    this.router.navigateByUrl(url);
                }
            }
            //disable back button for mandatory mfa
            if(localStorage.getItem('mfa') && localStorage.getItem('mfa') == 'mandatory') {
                this.isHiddenSkipButton = true;
            }
            this.subscribeTurnOffMFA();
            this.redirect = this.config.getParameterByName("redirect") ? true: false;
            var amplifyInfo = JSON.parse(localStorage.getItem('amplifyInfo'));
            if (amplifyInfo) {
                this.data.configureAmplify(amplifyInfo.client_id, amplifyInfo.user_pool);
            }
            this.user = await this.data.getCurrentUser();
            this.prefferedMfaMethod = this.user.preferredMFA;
            var me = this;
            this.user.getUserData(function (err, data) {
                if (data.UserMFASettingList) {
                    me.numberOfenabledMethods = data.UserMFASettingList.length;
                    me.isSMSEnabled = data.UserMFASettingList.includes('SMS_MFA');
                    me.isTotpEnabled = data.UserMFASettingList.includes('SOFTWARE_TOKEN_MFA');
                    if (me.isSMSEnabled) {
                        me.phonenumber = me.maskPhoneumber(me.user.attributes.phone_number);
                    }

                    if(me.isSMSEnabled || me.isTotpEnabled){
                        me.title = me.tr('access.mfa.protoManageTitle');
                        me.disableContinue = false;
                    }else if(me.redirect){
                        me.disableContinue = true;
                    }
                }else{
                    if(me.redirect){
                        me.disableContinue = true;
                    }
                }
                me.common.stopPageLoading();
            }, { bypassCache: true });
        }
        catch (e) {
            this.common.stopPageLoading();
            this.data.loginAction = "setupmfa";
            let url = '/login';
            if(this.config.getParameterByName('origin')) {
                url += '?origin=' + encodeURIComponent(this.config.getParameterByName('origin'));
            }
            if(this.config.getParameterByName('target')) {
                url += '&target=' + encodeURIComponent(this.config.getParameterByName('target'));
            }
            this.router.navigateByUrl(url);
            console.log("Error", e.message);
        }
    }
    setError(text:string){
        this.error = text;
    }
    clearError(){
        this.error = undefined;
    }
    showAppInfo(){
        this.isAppInfoShown = true;
    }

    subscribeEntryPointSource() {
        this.data.entryPoint.subscribe(entryPoint => {
            if (entryPoint == 'changePassword')
                this.newAccountOrResetAccount = true;
            else if (entryPoint == 'login')
                this.entryFromLoginPage = true;
        });
    }

    async subscribeTurnOffMFA() {
        this.data.msg.subscribe(msg => {
            if (msg == 'turnoff')
                this.turnOffMFA();
        });
    }
    private maskPhoneumber(phone) {
        let last4 = phone.substring(phone.length - 4);
        let mask = phone.substring(4, phone.length - 5).replace(/\d/g, "*");
        return mask + last4;
    }
    goBackToPhone() {
        this.clearError();
        this.verificationCodeSent = false;
    }

    openDialog() {
        const dialogRef = this.dialog.open(TurnOffDialog, {
            width: '500px',
            position: {
                top: '200px'
            },
            panelClass: 'turn-off-mfa-container'
        })
    }

    async showTotpForm() {
        this.showTotpCodeForm = true;
    }

    async turnOffMFA() {
        this.common.beginProgress();
        let isReAuthenticationRequired = await this.checkReAuthenticationSession();
        if (!isReAuthenticationRequired) {
            this.common.stopProgress();
            return;
        }
        var that = this;
        var smsSettings = null;
        var toptpSettings = null;
        if(this.isSMSEnabled){
            smsSettings = {
                Enabled: false,
                PreferredMfa: false
            }
        }
        if(this.isTotpEnabled){
            toptpSettings = {
                Enabled: false,
                PreferredMfa: false
            }
        }
        try{
            this.user.setUserMfaPreference(smsSettings, toptpSettings, function () {
                that.isTotpEnabled = false;
                that.isSMSEnabled = false;
                that.prefferedMfaMethod = "";
                that.numberOfenabledMethods = 0;
                if(that.numberOfenabledMethods == 0 && that.redirect){
                    that.disableContinue = true;
                }
                that.title = that.tr('access.mfa.title');
            });
            this.config.delete_cookie("mfa");
        }catch(e){
            this.setError(e.message);
        }
        // parallel calls to send notification & skip property for disabled mfa prompt
        await Promise.all([
            this.data.sendNotification(this.user, this.locale, "MFA_CHANGES"),
            this.data.skipMFAForUser(this.user, false)
        ]);
        this.common.stopProgress();
    }

    async setSMSAsdefault() {
        this.common.beginProgress();
        let isReAuthenticationRequired = await this.checkReAuthenticationSession();
        if (!isReAuthenticationRequired) {
            this.common.stopProgress();
            return;
        }
        await this.data.auth.setPreferredMFA(this.user, 'SMS');
        this.prefferedMfaMethod = 'SMS_MFA';
        this.common.stopProgress();
        await this.data.sendNotification(this.user, this.locale, "MFA_CHANGES");
    }

    async setTotpAsdefault() {
        this.common.beginProgress();
        let isReAuthenticationRequired = await this.checkReAuthenticationSession();
        if (!isReAuthenticationRequired) {
            this.common.stopProgress();
            return;
        }
        await this.data.auth.setPreferredMFA(this.user, 'TOTP');
        this.prefferedMfaMethod = 'SOFTWARE_TOKEN_MFA';
        this.common.stopProgress();
        await this.data.sendNotification(this.user, this.locale, "MFA_CHANGES");
    }

    resetPhone() {
        this.verificationCodeSent = false;
    }

    async redirectToOrigin(redirectToTarget) {
        if (redirectToTarget === false && this.numberOfenabledMethods == 0) {
            await this.data.skipMFAForUser(this.user, true);
        }
        this.accessRedirect(redirectToTarget);
    }

    async removeSMSMFA() {
        this.isDisableSMSClicked = true;
        this.common.beginProgress();
        let isReAuthenticationRequired = await this.checkReAuthenticationSession();
        if (!isReAuthenticationRequired) {
            this.common.stopProgress();
            return;
        }
        var that = this;
        this.user.setUserMfaPreference({
            Enabled: false,
            PreferredMfa: false
        }, null, async function (e) {
            if(e && e.code == 'InvalidParameterException'){
                that.setError(e.message);
                that.common.stopProgress();
                return;
            }
            that.isSMSEnabled = false;
            that.isDisableSMSClicked = true;
            that.numberOfenabledMethods--;
            if(that.isTotpEnabled){
                that.setTotpAsdefault();
            }else{
                that.prefferedMfaMethod = "";
                that.config.delete_cookie("mfa");
            }
            if(that.numberOfenabledMethods == 0 && that.redirect){
                that.disableContinue = true;
                await this.data.skipMFAForUser(this.user, false);
            }
            that.common.stopProgress();
            await this.data.sendNotification(this.user, "MFA_CHANGES");
        });
    }

    async removeTotpMFA() {
        this.common.beginProgress();
        let isReAuthenticationRequired = await this.checkReAuthenticationSession();
        if (!isReAuthenticationRequired) {
            this.common.stopProgress();
            return;
        }
        var that = this;
        this.user.setUserMfaPreference(null, {
            Enabled: false,
            PreferredMfa: false
        }, async function (e) {
            if(e && e.code == 'InvalidParameterException'){
                that.setError(e.message);
                that.common.stopProgress();
                return;
            }else{
                that.isTotpEnabled = false;
                that.numberOfenabledMethods--;
                that.common.stopProgress();
                if(that.isSMSEnabled){
                    that.setSMSAsdefault();
                }else{
                    that.prefferedMfaMethod = "";
                    that.config.delete_cookie("mfa");
                }
                if (that.numberOfenabledMethods == 0 && that.redirect) {
                    that.disableContinue = true;
                    await this.data.skipMFAForUser(this.user, false);
                }
                that.common.stopProgress();
                await this.data.sendNotification(this.user, "MFA_CHANGES");
            }

        });

    }

    async enableSMSMFA() {
        let isReAuthenticationRequired = await this.checkReAuthenticationSession();
        if (!isReAuthenticationRequired) {
            this.common.stopProgress();
            return;
        }
        this.showSMSSetup = true;
    }

    async enableTotpMFA() {
        this.isTotpClicked = true;
        let isReAuthenticationRequired = await this.checkReAuthenticationSession();
        if (!isReAuthenticationRequired) {
            this.common.stopProgress();
            return;
        }
        var code = await this.data.auth.setupTOTP(this.user);
        this.qrCodeString = code;
        let qrCodeIdentifier = this.data.username;
        if(!qrCodeIdentifier) {
            qrCodeIdentifier = this.user.attributes.email;
        }
        this.qrCode = `otpauth://totp/Basware Access:${qrCodeIdentifier}?secret=${code}&issuer=Basware_Access`;
        this.showTotpSetup = true;
        this.common.stopProgress();
    }

    showTotpVerification() {
        this.verificationCodeSent = true;
        this.showTotpCodeForm = false;
    }

    async getSMSCode() {
        this.clearError();
        if(this.phonenumber == ""){
            this.phoneRequired = true;
            return;
        }
        this.common.beginProgress();
        try {
            await this.data.auth.updateUserAttributes(this.user, {
                'phone_number': this.phonenumber
            }, {
                ...this.data.getCustomerIdAndShortName(),
                device_key: localStorage.getItem('deviceKey') ? localStorage.getItem('deviceKey'): "NA"
            });
            await this.data.auth.verifyCurrentUserAttribute("phone_number");
            this.verificationCodeSent = true;
        } catch (e) {
            console.log("Error verifying phone number", e);
            if(e.code == "InvalidParameterException"){
                this.setError(this.tr('access.mfa.invalidPhoneMumber'));
            }
            this.common.stopProgress();

        }
        this.common.stopProgress();
    }

    backToMFASetup() {
        this.clearError();
        this.isTotpClicked = false;
        this.showSMSSetup = false;
        this.showTotpSetup = false;
        this.verificationCodeSent = false;
    }

    async verifySMSCode() {
        this.clearError();
        // To verify attribute with the code
        try {
            if(this.securitycode == ""){
                this.smsRequired = true;
                return;
            }
            this.invalidCode = false;
            this.common.beginProgress();

            await this.data.auth.verifyCurrentUserAttributeSubmit("phone_number", this.securitycode);
            await this.data.auth.setPreferredMFA(this.user, 'SMS');
            this.isSMSEnabled = true;
            this.disableContinue = false;
            this.title = this.tr('access.mfa.protoManageTitle');
            this.prefferedMfaMethod = "SMS_MFA";
            this.backToMFASetup();
            this.config.setCookie("mfa", "enabled", 1);
            this.common.stopProgress();
            this.numberOfenabledMethods++;
            if (this.numberOfenabledMethods == 1){
                await this.data.skipMFAForUser(this.user, true);
            }
            await this.data.sendNotification(this.user, this.locale,"MFA_CHANGES");
        } catch (e) {
            if(e.name == "LimitExceededException"){
                this.setError(e.message);
            }
            else if (e.name == 'CodeMismatchException' || e.name == 'InvalidParameterException'){
                this.invalidCode = true;
            }else{
                this.setError(e.message);
            }
            this.common.stopProgress();
            console.log("Error verifying SMS code", e);
        }
    }
    async verifyChallengeAnswer() {
        try {
            this.clearError();
            if(this.challengeAnswer == ""){
                this.codeRequired = true;
                return;
            }
            this.invalidCode = false;
            this.common.beginProgress();
            await this.data.auth.verifyTotpToken(this.user, this.challengeAnswer);
            await this.data.auth.setPreferredMFA(this.user, 'TOTP');
            this.config.setCookie("mfa", "enabled", 1);
            this.isTotpEnabled = true;
            this.disableContinue = false;
            this.title = this.tr('access.mfa.protoManageTitle');
            this.backToMFASetup();
            this.prefferedMfaMethod = "SOFTWARE_TOKEN_MFA";
            this.common.stopProgress();
            this.numberOfenabledMethods++;
            if (this.numberOfenabledMethods == 1){
                await this.data.skipMFAForUser(this.user, true);
            }
            await this.data.sendNotification(this.user, this.locale,"MFA_CHANGES");
        }
        catch (e) {
            if (e.name == 'CodeMismatchException' || e.name == 'InvalidParameterException' || e.name == 'EnableSoftwareTokenMFAException'){
                this.invalidCode = true;
            }else{
                this.setError(e.message);
            }
            this.common.stopProgress();
            console.log("Error verifying SMS code", e);
        }
    }
    goBack() {
        this.clearError();
        this.showTotpSetup = false;
        this.isTotpClicked = false;
        this.showSMSSetup = false;
        this.verificationCodeSent = false;
    }

    goBackToQRCode() {
        this.clearError();
        this.isTotpClicked = false;
        this.showTotpCodeForm = false;
        this.verificationCodeSent = false;
        this.isAppInfoShown = false;
    }

    accessRedirect(redirectToTarget) {
        try {
            var target = this.config.getParameterByName('target');
            var origin = this.config.getParameterByName('origin');
            if (target && this.config.isAllowedToRedirect(target) && redirectToTarget && this.numberOfenabledMethods > 0) {
                window.location.href = decodeURIComponent(target);
            } else if (origin) {
                window.location.href = decodeURIComponent(origin);
            } else {
                this.router.navigate(['/applications']);
            }
        }
        catch (err) {
            this.setError(this.tr('access.common.notAllowed'));
        }
    }

    tr(text: string) {
        return this.localization.translate(text);
    }
}

@Component({
    selector: 'turnoff-dialog',
    templateUrl: 'dialog.html',
})
export class TurnOffDialog {
    constructor(
        public dialogRef: MatDialogRef<TurnOffDialog>,
        private localization: LocalizationService,
        private data: DataService
    ) { }

    onNoClick(): void {
        this.dialogRef.close();
    }
    turnOffMFA() {
        this.data.updateMsg('turnoff');
        this.dialogRef.close();
    }
    tr(text: string) {
        return this.localization.translate(text);
    }

}
