import {Injectable} from '@angular/core';
import {ConfigService} from './config.service';
import {HttpClient} from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class LocalizationService {

    constructor(
        private config: ConfigService,
        private http: HttpClient
    ) {

    }

    translateObject = {};
    defaultTranslateObject = {};
    supportedLangCodes = ['en-US','es-ES','fr-FR','pt-BR','th-TH','tr-TR','zh-CN','zh-HK','da-DK','de-DE','es-EC','fi-FI','it-IT','nb-NO','nl-NL','pl-PL','pt-PT','ru-RU','sv-SE','sk-SK'];

    resolveLocale() {
        let DEFAULT_LANGUAGE = 'en-US';
        let amplifyInfo = JSON.parse(localStorage.getItem('amplifyInfo'));
        let languagecode = null;
        if (amplifyInfo && amplifyInfo.languagecode) {
            languagecode = amplifyInfo.languagecode;
        }

        let locale = this.config.getParameterByName('languagecode') || languagecode || navigator.language;

        let deduced_locale;

        if (this.supportedLangCodes.includes(locale)) { // exact match of the locale
            deduced_locale = locale;
        } else {
            // if (this.supportedLangCodes.includes(locale.substring(0, 2))) { // first to characters match for the locale
            //     deduced_locale = locale.substring(0, 2);
            // } else {
            switch (locale) { // map specific cases
                case 'da':
                    deduced_locale = 'da-DK';
                    break;
                case 'de':
                    deduced_locale = 'de-DE';
                    break;
                case 'es':
                    deduced_locale = 'es-EC';
                    break;
                case 'fi':
                    deduced_locale = 'fi-FI';
                    break;
                case 'it':
                    deduced_locale = 'it-IT';
                    break;
                case 'nb':
                    deduced_locale = 'nb-NO';
                    break;
                case 'nl':
                    deduced_locale = 'nl-NL';
                    break;
                case 'pl':
                    deduced_locale = 'pl-PL';
                    break;
                case 'pt':
                    deduced_locale = 'pt-PT';
                    break;
                case 'ru':
                    deduced_locale = 'ru-RU';
                    break;
                case 'sv':
                    deduced_locale = 'sv-SE';
                    break;
                case 'fr':
                case 'fr-BE':
                case 'fr-CA':
                    deduced_locale = 'fr-FR';
                    break;
                case 'th':
                    deduced_locale = 'th-TH';
                    break;
                case 'tr':
                    deduced_locale = 'tr-TR';
                    break;                
                case 'zh':
                    deduced_locale = 'zh-CN';
                    break;                
                default: // all others will default to english like all en-XX codes and other unknowns
                    deduced_locale = DEFAULT_LANGUAGE;
            }
            //}
        }
        return deduced_locale;
    }

    async setLang() {

        let deduced_locale = this.resolveLocale();

        const langPath = `assets/locales/${deduced_locale}.json`;
        var obj = await this.http.get(langPath).toPromise();
        const defaultLangPath = `assets/locales/en-US.json`;
        if (langPath != defaultLangPath) {
            var obj1 = await this.http.get(defaultLangPath).toPromise();
            this.defaultTranslateObject = obj1;
        }
        this.translateObject = obj;
    }

    translate(text: string) {
        return this.translateObject[text] || this.defaultTranslateObject[text] || text;
    }
}
