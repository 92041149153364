import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';
import { ConfigService } from '../config.service';
import { LocalizationService } from '../localization.service';
import { CommonService } from '../common.service';
import {ProgressSpinnerMode} from '@angular/material/progress-spinner';
import {ThemePalette} from '@angular/material/core';
import {JwtHelperService} from '@auth0/angular-jwt';

@Component({
    selector: 'resetmfa',
    templateUrl: './resetmfa.component.html',
    styleUrls: ['./resetmfa.component.scss']
})
export class ResetMfaComponent implements OnInit {

    constructor(
        private data: DataService,
        private config: ConfigService,
        private localization: LocalizationService,
        public common: CommonService
    ) {
        common.title = "Reset 2-step verification";
        common.clearError();
    }   

    username: string;
    discovered = false;
    emailRequired = false;
    userPoolId = '';
    showMessage = false;
    messageText = '';
    noApplicationsError = false;
    mode: ProgressSpinnerMode = 'indeterminate';
    value = 32;
    color: ThemePalette = 'primary';

    async ngOnInit() {
        let idToken = "";
        if(this.data.samlUserDetails != null && this.data.samlUserDetails["id_token"] != null){            
            idToken = this.data.samlUserDetails["id_token"];
        }

        if(idToken == ""){
            this.common.title = "";
            this.noApplicationsError = true;
            this.showMessage = true
        }
        else {
            var decoded = new JwtHelperService().decodeToken(idToken);
            if(!decoded.permissions || decoded.permissions == '' || !decoded.permissions.includes("bwaccess.resetmfa.write")) {            
                this.common.title = "";
                this.noApplicationsError = true;
                this.showMessage = true
            }            
        }      
    }       

    async formButton(username: string) {
        if (!this.discovered)
            this.discover(username); 
        else
            this.submitMFAReset(username);      

        return false;
    }

    async discover(email: string) {        
        this.common.beginProgress();
        if (email == ""){
            this.emailRequired = true;
            this.common.stopProgress();
            return;
        }        

        try { 
            console.log('Discovering');
            const username = email.toLowerCase();
            const discovered = await this.data.discover(
                username,
                this.config.getParameterByName('customerID') || '',
                'RESET-MFA'                
            ).toPromise();

            //const discovered = false
            if (discovered) {
                this.data.username = email;
                this.discovered = true;
                // Decrypt                
                this.userPoolId = this.config.decrypt(username, discovered.body.user_pool);
                this.common.clearError();
            } else {
                this.discovered = false;
                this.common.setError("Invalid email address. Please check and try again.");                
            }
        }
        catch (err) {            
            console.log(err);
            this.common.setError("Invalid email address. Please check and try again.");            
        }

        this.common.stopProgress();
    }    

    async submitMFAReset(email) {        
        this.common.beginProgress();
        try {
            this.common.loading = true;        
            this.common.clearError();
            
            await this.data.setMFA(this.userPoolId, email, {
                enabled: false,
                preferred: false
            }, {
                enabled: false,
                preferred: false
            }, "RESET_MFA");

            this.showMessage = true;
            this.messageText = "Login information has been reset successfully. You can now close this browser tab or window."
        }
        catch (err) {            
            console.log(err);
            this.showMessage = true;
            this.messageText = "Resetting the login information failed, please check if the user has activated the account."
        }
        this.common.stopProgress();
    } 

    async back() {
        this.discovered = false;
        this.emailRequired = false;
        this.showMessage = false;
        this.username = '';
    }

    buttonText() {
        if (!this.discovered)
            return this.tr('access.login.next');                
        else
            return this.tr('access.forgot.mfa.send.request');
    }    

    tr(text: string) {
        return this.localization.translate(text);
    }    
}